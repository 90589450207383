:root {
  --primary-color: #8A2BE2;
  --secondary-color: #FDF5E6;
  --text-color: #333;
  --background-color: #FFF;
  --accent-color: #FFD700;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
}
.Home {
  padding-top: 100px; 
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

header {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

header.scrolled {
  padding: 10px 0;
  background-color: rgba(255, 255, 255, 0.95);
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-family: 'Playfair Display', serif;
  font-size: 28px;
  font-weight: 700;
  color: var(--primary-color);
  text-decoration: none;
  transition: all 0.3s ease;
}

.logo:hover {
  color: var(--accent-color);
}

nav ul {
  display: flex;
  list-style-type: none;
}

nav ul li {
  margin-left: 30px;
}

nav ul li a {
  text-decoration: none;
  color: var(--text-color);
  font-weight: 500;
  transition: color 0.3s ease;
  position: relative;
}

nav ul li a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: var(--primary-color);
  transition: width 0.3s ease;
}

nav ul li a:hover::after {
  width: 100%;
}

.hero {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-color);
  position: relative;
  overflow: hidden;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(138, 43, 226, 0.2), rgba(255, 215, 0, 0.2));
  z-index: 1;
}

.hero-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  position: relative;
  z-index: 2;
}

.hero-text {
  flex: 1;
  padding-right: 40px;
}

.hero h1 {
  font-family: 'Playfair Display', serif;
  font-size: 56px;
  margin-bottom: 20px;
  color: var(--primary-color);
  animation: fadeInUp 1s ease;
}

.hero p {
  font-size: 24px;
  margin-bottom: 30px;
  animation: fadeInUp 1s ease 0.2s;
  animation-fill-mode: both;
}

.cta-button {
  display: inline-block;
  background-color: var(--primary-color);
  color: white;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 50px;
  font-weight: 700;
  transition: all 0.3s ease;
  animation: fadeInUp 1s ease 0.4s;
  animation-fill-mode: both;
  margin-right: 15px;
  margin-top: 15px;
}
.cta-button .icon {
  font-size: 1.2em;
}
.cta-button:last-child {
  margin-right: 0;
}
.cta-button:hover {
  background-color: var(--accent-color);
  color: var(--text-color);
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
.dressme-button {
  background-color:#ff6f61; 
}
.dressme-button:hover {
  background-color:var(--secondary-color);
  color: var(--text-color);
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
.hero-slideshow {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  animation: fadeIn 1s ease 0.6s;
  animation-fill-mode: both;
}

.snapwidget-widget {
  border: none;
  overflow: hidden;
  width: 500px;
  height: 500px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

section {
  padding: 100px 0;
}

h2 {
  font-family: 'Playfair Display', serif;
  font-size: 42px;
  margin-bottom: 40px;
  text-align: center;
  color: var(--primary-color);
}

.about-section {
  background-color: #fff;
}

.about-section p {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 1.8;
}


.instagram-section {
  background: linear-gradient(45deg, #405DE6, #5851DB, #833AB4, #C13584, #E1306C, #FD1D1D);
  color: white;
  position: relative;
  overflow: hidden;
}

.instagram-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  z-index: 1;
}

.instagram-section .container {
  position: relative;
  z-index: 2;
}

.instagram-section h2 {
  color: white;
  margin-bottom: 30px;
}

.instagram-section a {
  color: white;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.instagram-section a:hover {
  opacity: 0.8;
}

.instagram-widget-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.contact-section {
  background-color: #fff;
}

.contact-section form {
  max-width: 600px;
  margin: 0 auto;
}

.contact-section label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
}

.contact-section input,
.contact-section textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 2px solid #e0e0e0;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.contact-section input:focus,
.contact-section textarea:focus {
  outline: none;
  border-color: var(--primary-color);
}

.contact-section button {
  background-color: var(--primary-color);
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 700;
  transition: all 0.3s ease;
  display: block;
  margin: 0 auto;
}

.contact-section button:hover {
  background-color: var(--accent-color);
  color: var(--text-color);
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

footer {
  background-color: var(--secondary-color);
  text-align: center;
  padding: 30px 0;
  color: var(--text-color);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .hero-content {
    flex-direction: column;
    text-align: center;
  }

  .hero-text {
    padding-right: 0;
    margin-bottom: 40px;
  }

  .hero-slideshow {
    justify-content: center;
  }

  .snapwidget-widget {
    width: 100%;
    max-width: 500px;
    height: auto;
    aspect-ratio: 1 / 1;
  }
}

@media (max-width: 768px) {
  nav {
    flex-direction: column;
  }

  nav ul {
    margin-top: 20px;
  }

  nav ul li {
    margin: 0 10px;
  }

  .hero h1 {
    font-size: 42px;
  }

  .hero p {
    font-size: 20px;
  }

  section {
    padding: 60px 0;
  }

  h2 {
    font-size: 36px;
  }
}


.featured-products {
  background-color: #f8f8f8;
  padding: 80px 0;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.product-card {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.product-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.product-info {
  padding: 20px;
}

.product-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.product-price {
  font-size: 16px;
  color: var(--primary-color);
  font-weight: 600;
}


.original-price {
  color: #888;
  text-decoration: line-through;
  font-weight: normal;
}

.discounted-price {
  color: var(--primary-color);
}

.discount-label {
  background-color: var(--primary-color);
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}
/* Add this to your CSS file */
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #007bff; /* Change color as needed */
  animation: bounce 0.6s infinite alternate;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}
.dress-me-btn-container {
  text-align: center;
  margin-top: 20px;
}

.dress-me-btn {
  background-color: #ff6347;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.dress-me-btn:hover {
  background-color: #ff4500;
}
/* Shimmer Effect CSS */
.shimmer-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.shimmer {
  background-color: #f6f7f8;
  background-image: linear-gradient(90deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 8px;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.shimmer-image {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.shimmer-line {
  height: 20px;
  width: 100%;
  margin-top: 10px;
}

.shimmer-price {
  height: 15px;
  width: 50%;
  margin-top: 10px;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px);
  }
}
