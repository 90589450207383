@keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .hero-text h1 {
    font-size: 3rem; /* Adjust font size as needed */
    background: linear-gradient(90deg, rgb(255, 0, 150), rgb(0, 153, 255), rgb(255, 255, 0));
    background-size: 200% 200%; /* Make the background gradient larger for the animation */
    color: transparent; /* Make the text color transparent to show the gradient */
    background-clip: text;
    -webkit-background-clip: text;
    animation: gradientAnimation 5s ease-in-out infinite; /* Animation with a 5s duration */
  }
  